import Link from "next/link";
import { ReactNode } from "react";
import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import { LinkField } from "@prismicio/types";
import { Button } from "@/components/Button";
import {
    Carousel,
    CarouselContent,
    CarouselDots,
    CarouselItem,
} from "@/components/Carousel";
import { Separator } from "@/components/Separator";
import { Image } from "@/lib/imgproxy";

/**
 * Props for `TestimonialsSlice`.
 */
export type TestimonialsSliceProps =
    SliceComponentProps<Content.TestimonialsSliceSlice>;

/**
 * Component for "TestimonialsSlice" Slices.
 */
const TestimonialsSlice = ({ slice }: TestimonialsSliceProps): JSX.Element => (
    <section
        data-slice-type={slice.slice_type}
        data-slice-variation={slice.variation}
        className="my-[70px] flex flex-col items-center gap-[60px] lg:my-[100px]"
    >
        <div className="flex flex-col gap-10">
            <div className="flex flex-col gap-5 text-center">
                <div className="typography-h2 lg:typography-h1 font-semibold text-blue-grey-900">
                    {slice.primary.title}
                </div>
                <div className="typography-main text-blue-grey">
                    {slice.primary.description}
                </div>
            </div>
            <div className="flex justify-center">
                <ActionBtn link={slice.primary.cta_link}>
                    {slice.primary.cta_text}
                </ActionBtn>
            </div>
        </div>
        <div className="flex w-full justify-center">
            <Carousel
                opts={{ align: "start", watchDrag: true }}
                className="items h-fit w-full flex-col gap-5"
            >
                <CarouselContent className="ml-4 mr-2 flex lg:ml-0 lg:justify-center">
                    {slice.primary.case_studies.map((cs) => (
                        <CarouselItem key={cs.name} className="flex">
                            <div className="my-3 w-[320px] flex-1 rounded-xl bg-white px-4 py-10 shadow-md lg:w-[387px]">
                                <div className="flex h-[172px] flex-col items-center gap-5 lg:h-[144px]">
                                    <div className="relative h-20 w-full">
                                        <Image
                                            alt={cs.logo.alt ?? ""}
                                            src={cs.logo.url ?? ""}
                                            layout="fill"
                                            objectFit="contain"
                                            unoptimized
                                            sizes="120px"
                                        />
                                    </div>
                                    <div className="flex flex-col items-center">
                                        <h3 className="typography-h3 line-clamp-2 text-center font-semibold text-blue-grey-900 lg:line-clamp-1">
                                            {cs.name}
                                        </h3>
                                        <span className="typography-tiny text-blue-grey">
                                            {cs.location}
                                        </span>
                                    </div>
                                </div>
                                <Separator className="my-8" />
                                <div className="flex flex-1 flex-col justify-between px-4 lg:flex-grow">
                                    <div className="typography-main px-1 text-center italic text-blue-grey">
                                        {cs.summary}
                                    </div>
                                    <div className="mt-auto flex items-center gap-4 pt-8">
                                        <div className="relative size-10 flex-shrink-0 lg:size-14">
                                            <Image
                                                alt={cs.avatar.alt ?? ""}
                                                src={cs.avatar.url ?? ""}
                                                layout="fill"
                                                objectFit="contain"
                                                unoptimized
                                                sizes="112px"
                                            />
                                        </div>
                                        <div className="typography-main text-blue-grey-400">
                                            {cs.client}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CarouselItem>
                    ))}
                </CarouselContent>
                <CarouselDots position="center" />
            </Carousel>
        </div>
    </section>
);

const ActionBtn = ({
    children,
    link,
}: {
    children: ReactNode;
    link: LinkField;
}): JSX.Element => {
    if (!("url" in link) || typeof window === "undefined") return <div />;
    if (!link.url) return <div>{children}</div>;
    const href = new URL(link.url ?? "", window.location.origin);
    const isSameSite = href.host === window.location.host;
    if (isSameSite)
        return (
            <Link href={href} passHref>
                <Button className="px-[42px] py-3" asChild>
                    <a className="remove-styles-a">{children}</a>
                </Button>
            </Link>
        );
    return (
        <a href={link.url} target="_blank">
            <Button className="px-[42px] py-3">{children}</Button>
        </a>
    );
};

export default TestimonialsSlice;
